import { AppUser } from '@/types'
import { ActionContext, ActionTree } from 'vuex'

interface AppState {
	token: string
	user: AppUser
	showSnackbar: boolean
	snackbarMessage: string
	snackbarColor: string
}

const appState: AppState = {
	token: sessionStorage.getItem('token') || '',
	user: sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user') as string) : {},
	showSnackbar: false,
	snackbarMessage: '',
	snackbarColor: '',
}

type Mutations = {
	[key: string]: any
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<AppState, AppState>, 'commit'>

interface Actions {
	setToken({ commit }: AugmentedActionContext, token: string): void
	setUser({ commit }: AugmentedActionContext, user: AppUser): void
	logout({ commit }: AugmentedActionContext): void
	showSnack({ commit }: AugmentedActionContext, payload: { message: string; color: string }): void
	hideSnack({ commit }: AugmentedActionContext): void
}

const actions: ActionTree<AppState, AppState> & Actions = {
	setToken({ commit }, token: string) {
		commit('setToken', token)
		sessionStorage.setItem('token', token)
	},
	setUser({ commit }, user: AppUser) {
		commit('setUser', user)
		sessionStorage.setItem('user', JSON.stringify(user))
	},
	logout({ commit }) {
		sessionStorage.clear()
		commit('setToken', '')
		commit('setUser', {})
	},
	showSnack({ commit }, payload: { message: string; color: string }) {
		commit('setSnackMessage', payload.message)
		commit('setSnackColor', payload.color)
		commit('showSnackbar')
	},
	hideSnack({ commit }) {
		commit('hideSnackbar')
	},
}

export const app = {
	namespaced: true,
	state: appState,
	mutations: {
		setToken(state: AppState, token: string) {
			state.token = token
		},
		setUser(state: AppState, user: AppUser) {
			state.user = user
		},
		setSnackMessage(state: AppState, message: string) {
			state.snackbarMessage = message
		},
		setSnackColor(state: AppState, color: string) {
			state.snackbarColor = color
		},
		showSnackbar(state: AppState) {
			state.showSnackbar = true
		},
		hideSnackbar(state: AppState) {
			state.showSnackbar = false
		},
	},
	actions,
	getters: {
		isAuthenticated(state: AppState) {
			return state.token !== ''
		},
	},
}
