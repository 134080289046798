import { get, post, patch, del, put } from './api'
import { User } from '@/types/users'

export interface PaginatedUsers {
	count: number
	rows: User[]
}

export function list(): Promise<User[]> {
	return get(`/api/users?rows=100`)
}

export function findAll(filters: any, page = 1, rows = 10): Promise<PaginatedUsers> {
	let url = `/api/users?page=${page}&rows=${rows}&paginated=1`
	if (filters.username) url += `&username=${filters.username}`
	if (filters.enabled) url += `&user_status=${filters.enabled}`
	if (filters.level !== null && filters.level !== undefined) url += `&level=${filters.level}`
	if (filters.selectedLevel !== null && filters.selectedLevel !== undefined) url += `&level=${filters.selectedLevel}`
	if (filters.customerId !== null && filters.customerId !== undefined) url += `&customer_id=${filters.customerId}`
	if (filters.partnerId !== null && filters.partnerId !== undefined) url += `&partner_id=${filters.partnerId}`
	if (filters.sortBy) url += `&sort_by=${filters.sortBy}`
	if (filters.sortDesc) url += `&sort_desc`
	return get(url)
}

export function save(data: any): Promise<User> {
	if (data.id) {
		return patch(`/api/users/${data.id}`, data)
	} else {
		return post('/api/users', data)
	}
}

export function getById(id: number): Promise<User> {
	return get(`/api/users/${id}`)
}

export function remove(id: number) {
	return del(`/api/users/${id}`)
}

export function resetPassword(id: number, noemail = false) {
	if (noemail) return post(`/api/auth/reset-password?noemail=1`, { id })
	return post(`/api/auth/reset-password`, { id })
}

export function setPassword(password: string, token: string) {
	return put(`/api/auth/reset-password`, { password, reset_token: token })
}
export function verifyResetToken(token: string) {
	return post(`/api/auth/check-reset-token`, { token })
}

export function enable(id: number) {
	return patch(`/api/users/${id}/enable`, {})
}
export function disable(id: number) {
	return patch(`/api/users/${id}/disable`, {})
}
export function impersonate(id: number) {
	return post(`/api/auth/impersonate`, { id })
}
