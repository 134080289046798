import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
	{
		path: '/',
		redirect: '/dashboard',
	},
	{
		// load the dashboard dynamically to avoid undefined variables
		path: '/dashboard',
		name: 'Dashboard',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/dashboard/partner/:partnerId',
		name: 'PartnerDashboard',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/dashboard/customer/:customerId',
		name: 'CustomerDashboard',
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/alerts/:page?/:rows?',
		name: 'Alerts',
		// component: Alerts,
		component: () => import(/* webpackChunkName: "alerts" */ '../views/Alerts.vue'),
	},
	{
		path: '/alert/:id',
		name: 'AlertDetail',
		// component: Alerts,
		component: () => import(/* webpackChunkName: "alertdetail" */ '../views/AlertDetail.vue'),
	},
	{
		path: '/company',
		redirect: '/company/customers',
		component: () => import(/* webpackChunkName: "company" */ '../views/Company.vue'),
		children: [
			{
				path: 'customers/create',
				name: 'CustomerForm',
				meta: { tab: 0 },
				components: {
					default: () => import(/* webpackChunkName: "company" */ '../views/company/CustomerForm.vue'),
					top: () => import(/* webpackChunkName: "company" */ '../views/company/CustomerHeader.vue'),
					aside: () => import(/* webpackChunkName: "company" */ '../views/company/CustomerFilters.vue'),
				},
			},
			{
				path: 'customers/edit/:id',
				name: 'EditCustomer',
				meta: { tab: 0 },
				components: {
					default: () => import(/* webpackChunkName: "company" */ '../views/company/CustomerForm.vue'),
					top: () => import(/* webpackChunkName: "company" */ '../views/company/CustomerHeader.vue'),
					aside: () => import(/* webpackChunkName: "company" */ '../views/company/CustomerFilters.vue'),
				},
			},
			{
				path: 'customers/:page?/:rows?',
				name: 'Customers',
				meta: { tab: 0 },
				components: {
					default: () => import(/* webpackChunkName: "company" */ '../views/company/CustomerList.vue'),
					top: () => import(/* webpackChunkName: "company" */ '../views/company/CustomerHeader.vue'),
					aside: () => import(/* webpackChunkName: "company" */ '../views/company/CustomerFilters.vue'),
				},
			},
			{
				path: 'users/create',
				name: 'UserForm',
				meta: { tab: 1 },
				components: {
					default: () => import(/* webpackChunkName: "company" */ '../views/company/UserForm.vue'),
					top: () => import(/* webpackChunkName: "company" */ '../views/company/UserHeader.vue'),
					aside: () => import(/* webpackChunkName: "company" */ '../views/company/UserFilters.vue'),
				},
			},
			{
				path: 'users/edit/:id',
				name: 'UserEdit',
				meta: { tab: 1 },
				components: {
					default: () => import(/* webpackChunkName: "company" */ '../views/company/UserForm.vue'),
					top: () => import(/* webpackChunkName: "company" */ '../views/company/UserHeader.vue'),
					aside: () => import(/* webpackChunkName: "company" */ '../views/company/UserFilters.vue'),
				},
			},
			{
				path: 'users/:page?/:rows?',
				name: 'Users',
				meta: { tab: 1 },
				components: {
					default: () => import(/* webpackChunkName: "company" */ '../views/company/UserList.vue'),
					top: () => import(/* webpackChunkName: "company" */ '../views/company/UserHeader.vue'),
					aside: () => import(/* webpackChunkName: "company" */ '../views/company/UserFilters.vue'),
				},
			},
			{
				path: 'contacts/:page?/:rows?',
				name: 'Contacts',
				meta: { tab: 2 },
				components: {
					default: () => import(/* webpackChunkName: "company" */ '../views/company/ContactList.vue'),
					top: () => import(/* webpackChunkName: "company" */ '../views/company/ContactHeader.vue'),
					aside: () => import(/* webpackChunkName: "company" */ '../views/company/ContactFilters.vue'),
				},
			},
			{
				path: 'contacts/create',
				name: 'ContactForm',
				meta: { tab: 2 },
				components: {
					default: () => import(/* webpackChunkName: "company" */ '../views/company/ContactForm.vue'),
					top: () => import(/* webpackChunkName: "company" */ '../views/company/ContactHeader.vue'),
					aside: () => import(/* webpackChunkName: "company" */ '../views/company/ContactFilters.vue'),
				},
			},
		],
	},
	{
		path: '/exclusion-rules',
		redirect: '/exclusion-rules/trusted-flows',
		component: () => import(/* webpackChunkName: "exclusionrules" */ '../views/ExclusionRules.vue'),
		children: [
			{
				path: 'trusted-flows/create',
				name: 'CreateTrustedFlow',
				meta: { tab: 0 },
				components: {
					default: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/TrustedFlowForm.vue'),
					top: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/TrustedFlowHeader.vue'),
					aside: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/TrustedFlowFilters.vue'),
				},
			},
			{
				path: 'trusted-flows/edit/:id',
				name: 'EditTrustedFlow',
				meta: { tab: 0 },
				components: {
					default: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/TrustedFlowForm.vue'),
					top: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/TrustedFlowHeader.vue'),
					aside: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/TrustedFlowFilters.vue'),
				},
			},
			{
				path: 'trusted-flows/:page?/:rows?',
				name: 'TrustedFlows',
				meta: { tab: 0 },
				components: {
					default: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/TrustedFlows.vue'),
					top: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/TrustedFlowHeader.vue'),
					aside: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/TrustedFlowFilters.vue'),
				},
			},
			{
				path: 'ignored-flows/create',
				name: 'CreateIgnoredFlow',
				meta: { tab: 1 },
				components: {
					default: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/IgnoredFlowForm.vue'),
					top: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/IgnoredFlowHeader.vue'),
					aside: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/IgnoredFlowFilters.vue'),
				},
			},
			{
				path: 'ignored-flows/edit/:id',
				name: 'EditIgnoredFlow',
				meta: { tab: 1 },
				components: {
					default: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/IgnoredFlowForm.vue'),
					top: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/IgnoredFlowHeader.vue'),
					aside: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/IgnoredFlowFilters.vue'),
				},
			},
			{
				path: 'ignored-flows/:page?/:rows?',
				name: 'IgnoredFlows',
				meta: { tab: 1 },
				components: {
					default: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/IgnoredFlows.vue'),
					top: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/IgnoredFlowHeader.vue'),
					aside: () =>
						import(/* webpackChunkName: "exclusionrules" */ '../views/exclusion/IgnoredFlowFilters.vue'),
				},
			},
		],
	},

	{
		path: '/logs/:page?/:rows?',
		name: 'Logs',
		component: () => import(/* webpackChunkName: "logs" */ '../views/Logs.vue'),
	},
	{
		path: '/map',
		name: 'Map',
		component: () => import(/* webpackChunkName: "map-view" */ '../views/Map.vue'),
	},
	{
		path: '/diagram',
		name: 'Diagram',
		component: () => import(/* webpackChunkName: "diagram-view" */ '../views/Diagram.vue'),
	},
	{
		path: '/timeline',
		name: 'Timeline',
		component: () => import(/* webpackChunkName: "timeline-view" */ '../views/Timeline.vue'),
	},
	{
		path: '/reports/create',
		name: 'CreateReport',
		component: () => import(/* webpackChunkName: "reportform" */ '../views/ReportForm.vue'),
	},
	{
		path: '/reports/edit/:id',
		name: 'EditReport',
		component: () => import(/* webpackChunkName: "reportform" */ '../views/ReportForm.vue'),
	},
	{
		path: '/reports/:page?/:rows?',
		name: 'Reports',
		component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue'),
	},
	{
		path: '/map/customer/:customerId',
		name: 'CustomerMap',
		component: () => import(/* webpackChunkName: "map-view" */ '../views/Map.vue'),
	},
	{
		path: '/diagram/customer/:customerId',
		name: 'CustomerDiagram',
		component: () => import(/* webpackChunkName: "diagram-view" */ '../views/Diagram.vue'),
	},
	{
		path: '/timeline/customer/:customerId',
		name: 'CustomerTimeline',
		component: () => import(/* webpackChunkName: "timeline-view" */ '../views/Timeline.vue'),
	},
	{
		path: '/profile',
		name: 'Profile',
		component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
	},
	{
		path: '/sites',
		name: 'Sites',
		component: () => import(/* webpackChunkName: "sites" */ '../views/Sites.vue'),
	},
	{
		path: '/solution/create',
		name: 'SoutionForm',
		component: () => import(/* webpackChunkName: "solutions" */ '../views/SolutionForm.vue'),
	},
	{
		path: '/solution/edit/:id',
		name: 'EditSolution',
		component: () => import(/* webpackChunkName: "solutions" */ '../views/SolutionForm.vue'),
	},
	{
		path: '/workgroup',
		name: 'Workgroup',
		component: () => import(/* webpackChunkName: "workgroup" */ '../views/Workgroup.vue'),
	},
	{
		path: '/workgroup/create',
		name: 'CreateUser',
		component: () => import(/* webpackChunkName: "userform" */ '../views/UserForm.vue'),
	},
	{
		path: '/workgroup/edit/:id',
		name: 'EditUser',
		component: () => import(/* webpackChunkName: "userform" */ '../views/UserForm.vue'),
	},
	// {
	// 	path: '/webhooks',
	// 	name: 'Webhooks',
	// 	component: () => import(/* webpackChunkName: "webhooks" */ '../views/Webhooks.vue'),
	// },
	{
		path: '/webhooks/create',
		name: 'CreateWebhook',
		component: () => import(/* webpackChunkName: "webhooks" */ '../views/WebhookForm.vue'),
	},
	{
		path: '/webhooks/edit/:id',
		name: 'EditWebhook',
		component: () => import(/* webpackChunkName: "webhooks" */ '../views/WebhookForm.vue'),
	},
	{
		path: '/rules/sid/:sid',
		name: 'SidView',
		component: () => import(/* webpackChunkName: "rules" */ '../views/SidOverrideView.vue'),
	},
	{
		path: '/rules/syslog/:sid',
		name: 'SyslogView',
		component: () => import(/* webpackChunkName: "rules" */ '../views/SyslogView.vue'),
	},
	{
		path: '/rules/override/:sid',
		name: 'SidEdit',
		component: () => import(/* webpackChunkName: "rules" */ '../views/SidOverrideForm.vue'),
	},
	{
		path: '/rules/request-counts/:sid',
		name: 'RequestCounts',
		component: () => import(/* webpackChunkName: "rules" */ '../views/SidRequestCounts.vue'),
	},
	{
		path: '/rules',
		redirect: '/rules/sids',
		component: () => import(/* webpackChunkName: "rules" */ '../views/Rules.vue'),
		children: [
			{
				path: 'sids/:page?/:rows?',
				name: 'ListRules',
				meta: { tab: 0 },
				components: {
					default: () => import(/* webpackChunkName: "rules" */ '../views/rules/Rules.vue'),
					top: () => import(/* webpackChunkName: "rules" */ '../views/rules/RuleHeader.vue'),
					aside: () => import(/* webpackChunkName: "rules" */ '../views/rules/RuleFilters.vue'),
				},
			},
			{
				path: 'overrides/:page?/:rows?',
				name: 'ListOverrides',
				meta: { tab: 1 },
				components: {
					default: () => import(/* webpackChunkName: "rules" */ '../views/rules/Overrides.vue'),
					top: () => import(/* webpackChunkName: "rules" */ '../views/rules/OverrideHeader.vue'),
					aside: () => import(/* webpackChunkName: "rules" */ '../views/rules/OverrideFilters.vue'),
				},
			},
			{
				path: 'master-rules/:page?/:rows?',
				name: 'ListMasterRules',
				meta: { tab: 2 },
				components: {
					default: () => import(/* webpackChunkName: "rules" */ '../views/rules/MasterRules.vue'),
					top: () => import(/* webpackChunkName: "rules" */ '../views/rules/MasterRuleHeader.vue'),
					aside: () => import(/* webpackChunkName: "rules" */ '../views/rules/MasterRuleFilters.vue'),
				},
			},
		],
	},
	{
		path: '/notifications',
		redirect: '/notifications/recipients',
		component: () => import(/* webpackChunkName: "notifications" */ '../views/Notifications.vue'),
		children: [
			{
				path: 'recipients/:page?/:rows?',
				name: 'Recipients',
				meta: { tab: 0 },
				components: {
					default: () =>
						import(/* webpackChunkName: "notifications" */ '../views/notifications/Recipients.vue'),
					top: () =>
						import(/* webpackChunkName: "notifications" */ '../views/notifications/RecipientHeader.vue'),
					aside: () =>
						import(/* webpackChunkName: "notifications" */ '../views/notifications/RecipientFilters.vue'),
				},
			},
			{
				path: 'webhooks',
				name: 'Webhooks',
				meta: { tab: 1 },
				components: {
					default: () =>
						import(/* webpackChunkName: "notifications" */ '../views/notifications/Webhooks.vue'),
					top: () =>
						import(/* webpackChunkName: "notifications" */ '../views/notifications/WebhookHeader.vue'),
					// aside: () =>
					// 	import(/* webpackChunkName: "notifications" */ '../views/notifications/WebhookFilters.vue'),
				},
			},
		],
	},
	{
		path: '/overrides/id/:id',
		name: 'OverrideView',
		component: () => import(/* webpackChunkName: "overrides" */ '../views/OverrideView.vue'),
	},
	{
		path: '/overrides/edit/:id',
		name: 'OverrideEdit',
		component: () => import(/* webpackChunkName: "overrides" */ '../views/OverrideForm.vue'),
	},

	{
		path: '/master-rules/id/:id',
		name: 'MasterRuleView',
		component: () => import(/* webpackChunkName: "masters" */ '../views/MasterRuleView.vue'),
	},
	{
		path: '/master-rules/edit/:id',
		name: 'MasteRuleEdit',
		component: () => import(/* webpackChunkName: "masters" */ '../views/MasterRuleForm.vue'),
	},

	{
		path: '/orders',
		redirect: '/orders/requests',
		component: () => import(/* webpackChunkName: "orders" */ '../views/Orders.vue'),
		children: [
			{
				path: 'requests/:page?/:rows?',
				name: 'ListRequests',
				meta: { tab: 0 },
				components: {
					default: () => import(/* webpackChunkName: "orders" */ '../views/orders/Requests.vue'),
					top: () => import(/* webpackChunkName: "orders" */ '../views/orders/RequestHeader.vue'),
					aside: () => import(/* webpackChunkName: "orders" */ '../views/orders/RequestFilters.vue'),
				},
			},
			{
				path: 'appliances/:page?/:rows?',
				name: 'ListAppliances',
				meta: { tab: 1 },
				components: {
					default: () => import(/* webpackChunkName: "orders" */ '../views/orders/Appliances.vue'),
					top: () => import(/* webpackChunkName: "orders" */ '../views/orders/ApplianceHeader.vue'),
					aside: () => import(/* webpackChunkName: "orders" */ '../views/orders/ApplianceFilters.vue'),
				},
			},
		],
	},
	{
		path: '/requests/edit/:id',
		name: 'EditRequest',
		component: () => import(/* webpackChunkName: "orders" */ '../views/RequestForm.vue'),
	},
	{
		path: '/appliances/create',
		name: 'CreateAppliance',
		component: () => import(/* webpackChunkName: "orders" */ '../views/ApplianceForm.vue'),
	},
	{
		path: '/appliances/edit/:id',
		name: 'EditAppliance',
		component: () => import(/* webpackChunkName: "orders" */ '../views/ApplianceForm.vue'),
	},
	{
		path: '/appliances/transfer/:id',
		name: 'TransferAppliance',
		component: () => import(/* webpackChunkName: "orders" */ '../views/ApplianceTransferForm.vue'),
	},
	{
		path: '/mail',
		redirect: '/mail/mailing',
		component: () => import(/* webpackChunkName: "orders" */ '../views/Mailing.vue'),
		children: [
			{
				path: 'mailing',
				name: 'Mailing',
				meta: { tab: 0 },
				components: {
					default: () => import(/* webpackChunkName: "mail" */ '../views/mail/MailingForm.vue'),
					top: () => import(/* webpackChunkName: "mail" */ '../views/mail/MailingHeader.vue'),
				},
			},
			{
				path: 'templates',
				name: 'Templates',
				meta: { tab: 1 },
				components: {
					default: () => import(/* webpackChunkName: "mail" */ '../views/mail/Templates.vue'),
					top: () => import(/* webpackChunkName: "mail" */ '../views/mail/TemplateHeader.vue'),
				},
			},
			{
				path: 'templates/edit/:id',
				name: 'TemplateEdit',
				meta: { tab: 1 },
				components: {
					default: () => import(/* webpackChunkName: "mail" */ '../views/mail/TemplateForm.vue'),
					top: () => import(/* webpackChunkName: "mail" */ '../views/mail/TemplateHeader.vue'),
				},
			},
		],
	},
	{
		path: '/ips',
		redirect: '/ips/list',
		component: () => import(/* webpackChunkName: "ips" */ '../views/Ips.vue'),
		children: [
			{
				path: 'list',
				name: 'IpList',
				meta: { tab: 0 },
				components: {
					default: () => import(/* webpackChunkName: "ips" */ '../views/ips/IpList.vue'),
					top: () => import(/* webpackChunkName: "ips" */ '../views/ips/IpListHeader.vue'),
					aside: () => import(/* webpackChunkName: "ips" */ '../views/ips/IpFilters.vue'),
				},
			},
			{
				path: 'groups',
				name: 'IpGroups',
				meta: { tab: 1 },
				components: {
					default: () => import(/* webpackChunkName: "ips" */ '../views/ips/IpGroupList.vue'),
					top: () => import(/* webpackChunkName: "ips" */ '../views/ips/IpGroupHeader.vue'),
					aside: () => import(/* webpackChunkName: "ips" */ '../views/ips/IpFilters.vue'),
				},
			},
		],
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
