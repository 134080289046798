<template>
	<v-app v-if="isAuthenticated && !isPasswordReset">
		<v-navigation-drawer v-model="drawer" app expand-on-hover mini-variant permanent>
			<v-sheet color=" lighten-4" class="pa-4 d-flex sheet">
				<v-avatar class="" color="grey darken-1 avatar" size="32">
					<img :src="`https://www.gravatar.com/avatar/${$store.state.app.user.emailHash}?d=mp`" />
				</v-avatar>

				<div class="ml-4">{{ $store.state.app.user.email }}</div>
			</v-sheet>
			<v-list dense nav>
				<div v-for="item in menuItems" :key="item.title">
					<router-link :to="item.to" v-if="!item.hidden" tag="v-list-item" link>
						<v-list-item-icon>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</router-link>
				</div>
			</v-list>
		</v-navigation-drawer>

		<v-system-bar app>
			<img src="img/logo-mini.png" class="small-logo" />
			<v-spacer></v-spacer>
			<div></div>

			<v-menu content-class="profile" v-if="manager">
				<template v-slot:activator="{ on: menu, attrs }">
					<div v-bind="attrs" v-on="{ ...menu }">
						{{ user.username }}
						<span v-if="$store.state.app.user.impersonatedBy">
							(impersonated by {{ $store.state.app.user.impersonatedBy }})
						</span>
					</div>
				</template>
				<v-sheet color="" class="pa-4 d-flex sheet">
					<v-avatar class="" color="grey darken-1 avatar" size="48">
						<img :src="`https://www.gravatar.com/avatar/${$store.state.app.user.emailHash}?d=mp`" />
					</v-avatar>

					<div class="ml-4">
						<div>{{ $store.state.app.user.username }}</div>
						<div>{{ $store.state.app.user.email }}</div>
						<div>{{ $store.state.app.user.companyName }}</div>
					</div>
				</v-sheet>
				<v-divider />
				<v-sheet class="pa-4 lighten-2">
					<div class="text-center">
						<div>
							You account manager is:
						</div>
						<div>
							{{ manager.company_name }}
						</div>
						<div>
							{{ manager.contact_person }}
						</div>
					</div>
				</v-sheet>
				<v-divider />
				<v-sheet color=" " class="pa-4 lighten-2">
					<div class="">
						<div class="">
							<v-icon color="blue" class="mr-8 text--blue lighten-4">mdi-email</v-icon>
							<a :href="`mailto:${manager.email}`">{{ manager.email }}</a>
						</div>
						<div>
							<v-icon color="blue" class="mr-8">mdi-phone</v-icon>
							<a :href="`tel:${manager.phone}`">{{ manager.phone }}</a>
						</div>
					</div>
				</v-sheet>
				<div v-if="$store.state.app.user.roleId === 1" @click.stop>
					<v-divider />
					<v-sheet color=" " class="pa-4 lighten-2">
						<div class="">
							<v-select label="Impersonate user" :items="usersToImpersonate" v-model="userToImpersonate">
							</v-select>
							<v-btn @click="impersonate">Impersonate</v-btn>
						</div>
					</v-sheet>
				</div>
				<v-divider />
				<v-sheet color=" " class="pa-4 lighten-2">
					<div class="">
						<div class=""><router-link to="/profile">Profile</router-link></div>
						<div>
							<router-link to="/" event @click.native.prevent="confirmResetPassword">
								Change password
							</router-link>
						</div>
						<div><router-link to="/" event @click.native.prevent="logout">Logout</router-link></div>
					</div>
				</v-sheet>
			</v-menu>
		</v-system-bar>
		<tile :loading="isLoading" v-if="isLoading"></tile>
		<v-main v-if="!isLoading">
			<router-view />
			<v-snackbar :value="showSnackbar">
				<div :class="`${snackbarColor}--text`">{{ snackbarMessage }}</div>

				<template v-slot:action="{ attrs }">
					<v-btn color="blue" text v-bind="attrs" @click="hideSnack">
						Close
					</v-btn>
				</template>
			</v-snackbar>
		</v-main>
		<v-dialog v-model="resetPasswordDialog" persistent max-width="350">
			<v-card class="lighten-1" elevation="4">
				<v-card-title class="headline">
					Reset password
				</v-card-title>

				<v-card-text>
					Are you sure ?
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>

					<v-btn color=" darken-1" elevation="2" text @click="resetPasswordDialog = false">
						Cancel
					</v-btn>

					<v-btn color="blue darken-1" elevation="2" text @click="requestReset">
						Confirm
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
	<v-app v-else-if="mfaChallenge">
		<div class="login">
			<div class="box">
				<v-card elevation="4">
					<v-form style="padding: 30px" @submit.prevent="login">
						<v-container>
							<div class="red--text mb-4" v-if="mfaError">
								{{ mfaError }}
							</div>
							<v-row>
								<div class="logo">
									<img src="img/logo-medium.png" />
								</div>
							</v-row>
							<v-row v-if="qrCode" class="d-flex justify-center mb-4">
								<img :src="qrCode" />
							</v-row>
							<v-row>
								<v-row>
									<v-text-field autofocus v-model="mfaCode" label="Code" outlined></v-text-field>
								</v-row>
							</v-row>

							<v-row style="display:flex;flex-direction: column;align-items:center">
								<v-btn type="submit" elevation="2">Verify</v-btn>
							</v-row>
						</v-container>
					</v-form>
				</v-card>
			</div>
		</div>
	</v-app>
	<v-app v-else-if="!isPasswordReset">
		<div class="login">
			<div class="box">
				<v-card elevation="4">
					<v-form style="padding: 30px" @submit.prevent="login">
						<v-container>
							<v-row>
								<div class="logo">
									<img src="img/logo-medium.png" />
								</div>
							</v-row>
							<div class="red--text mb-4" v-if="loginError">
								Invalid username and / or password
							</div>
							<v-row>
								<v-text-field autofocus v-model="username" label="E-mail" outlined></v-text-field>
							</v-row>
							<v-row>
								<v-text-field
									v-model="password"
									type="password"
									label="Password"
									outlined
								></v-text-field>
							</v-row>
							<v-row style="display:flex;flex-direction: column;align-items:center">
								<v-btn type="submit" elevation="2">Log in</v-btn>
							</v-row>
						</v-container>
					</v-form>
				</v-card>
			</div>
		</div>
	</v-app>

	<v-app v-else>
		<div class="login">
			<div class="box">
				<v-card v-if="isVerifyingToken">
					<v-sheet style="padding: 30px">
						<div class="text-center mb-8">
							Verifying token. Please wait...
						</div>
					</v-sheet>
				</v-card>
				<password-reset-form v-else-if="isValidResetToken" />
				<v-card v-else-if="!isVerifyingToken">
					<v-sheet style="padding: 30px">
						<div class="text-center mb-8">
							<h4>{{ resetTokenError }}</h4>
							Please use the link you received via email to reset you password.
						</div>
					</v-sheet>
				</v-card>
			</div>
		</div>
		<v-dialog v-model="passError" max-width="375" persistent>
			<v-card>
				<v-card-title class="headline">
					Error
				</v-card-title>

				<v-card-text>
					The user was not found or the token has expired
				</v-card-text>

				<v-card-actions class="d-flex justify-center">
					<v-btn color="red darken-1" elevation="2" text @click="passError = false">
						Ok
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-app>
</template>

<script lang="ts">
/* 
	eslint-disable 
	@typescript-eslint/no-unused-vars,
	@typescript-eslint/no-empty-function
*/
import { Component, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { AppUser, CustomerDevicesMap } from '@/types'
import { getCustomerDevices, getManager } from '@/api/me'
import { resetPassword, verifyResetToken, findAll as findAllUsers, impersonate as impersonateUser } from '@/api/users'
// import zxcvbn from 'zxcvbn'
import PasswordResetForm from '@/components/forms/ResetPasswordForm.vue'
import { getAllEndUsers } from './api/companies'

const appModule = namespace('app')

@Component({
	components: {
		PasswordResetForm,
	},
})
export default class App extends Vue {
	@appModule.Getter('isAuthenticated') isAuthenticated!: boolean
	@appModule.State('showSnackbar') showSnackbar!: boolean
	@appModule.State('snackbarMessage') snackbarMessage!: boolean
	@appModule.State('snackbarColor') snackbarColor!: boolean
	@appModule.State('user') user!: AppUser
	@appModule.Action('showSnack') private showSnack(payload: { message: string; color: string }) {}

	public drawer: any = null
	public manager: any = null
	public username = ''
	public password = ''
	// public showPassword = false
	public passError = false
	public loginError = false
	public defaultMenuItems = [
		{ title: 'Dashboard', icon: 'mdi-view-dashboard', to: '/dashboard' },
		{ title: 'Incidents', icon: 'mdi-alert-circle', to: '/alerts' },
		{ title: 'Exclusion rules', icon: 'mdi-alert-circle-check', to: '/exclusion-rules' },
		{ title: 'Sites', icon: 'mdi-office-building-outline', to: '/sites' },
		{ title: 'Private hosts', icon: 'mdi-ip', to: '/ips' },
		{ title: 'My workgroup', icon: 'mdi-account-group', to: '/workgroup' },
		// { title: 'Webhooks', icon: 'mdi-webhook', to: '/webhooks' },
		{ title: 'Customers', icon: 'mdi-account-circle', to: '/company', hidden: true },
		{ title: 'Reporting', icon: 'mdi-file-chart', to: '/reports' },
		{ title: 'Notifications', icon: 'mdi-webhook', to: '/notifications' },
		{ title: 'Logs', icon: 'mdi-folder-text', to: '/logs' },
	]
	public menuItems: any[] = []
	public timeout: any = null
	private isLoading = true
	private resetPasswordDialog = false
	private isValidResetToken = false
	private isVerifyingToken = true
	private passwordErrors: string[] = []
	private resetTokenError = ''
	private qrCode = ''
	private mfaCode = ''
	private mfaError = ''
	private mfaChallenge = false
	private usersToImpersonate: any[] = []
	private userToImpersonate = 0

	private get isPasswordReset() {
		return this.$route.path === '/reset-password'
	}

	public login() {
		this.loginError = false
		this.mfaError = ''
		const body: any = { username: this.username, password: this.password }
		if (this.mfaCode) {
			body.mfaCode = this.mfaCode
		}
		fetch('/api/auth/token', {
			method: 'post',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(body),
		})
			.then(resp => resp.json())
			.then(json => {
				// console.log(json.message)
				if (json.message) {
					this.loginError = true
				} else if (json.mfaError) {
					this.mfaError = 'Invalid code or code expired, please try again'
				} else if (json.mfaChallenge) {
					this.mfaChallenge = true
					this.qrCode = json.qrCode
				} else {
					this.$store.dispatch('app/setToken', json.token)
					this.$store.dispatch('app/setUser', json.user)
					this.loadData()
				}
			})
			.catch(err => {
				console.error('err', err)
			})
	}

	public logout() {
		this.qrCode = ''
		this.mfaChallenge = false
		this.$router.push('/')
		this.$store.dispatch('app/logout')
	}

	public confirmResetPassword() {
		this.resetPasswordDialog = true
	}

	public async requestReset() {
		try {
			const resp = await resetPassword(this.user.id, true)
			this.resetPasswordDialog = false
			this.$router.push({ path: '/reset-password', query: { token: resp.token } })
		} catch (e) {
			this.showSnack({ message: 'An error occured', color: 'red' })
			this.resetPasswordDialog = false
		}
	}

	@Watch('showSnackbar')
	private onSnack() {
		if (this.showSnackbar) {
			this.timeout = setTimeout(() => {
				this.$store.dispatch('app/hideSnack')
			}, 6000)
		}
	}

	private hideSnack() {
		clearTimeout(this.timeout)
		this.$store.dispatch('app/hideSnack')
	}

	private async verifyToken() {
		// console.log('verifyy')
		this.isVerifyingToken = true
		const resp = await verifyResetToken((this.$route.query as any).token)
		// console.log(resp)
		if (resp.success) {
			this.isValidResetToken = true
		} else {
			this.isValidResetToken = false
			this.resetTokenError = resp.error
		}
		this.isVerifyingToken = false
	}

	protected mounted() {
		if (this.isPasswordReset) {
			this.verifyToken()
		} else if (this.isAuthenticated) {
			this.loadData()
		}
	}

	private async loadData() {
		this.isLoading = true
		getCustomerDevices().then((devices: CustomerDevicesMap) => {
			// console.log('devices', devices)
			this.$store.dispatch('devices/setCustomerDevices', devices)
			this.isLoading = false
		})
		this.manager = await getManager()
		this.menuItems = [...this.defaultMenuItems]
		const item = this.menuItems.find(i => i.title === 'Customers') as any
		item.hidden = this.user.roleId < 4 ? false : true
		if (this.user.roleId === 1 && !this.menuItems.find(item => item.title === 'Mail')) {
			this.menuItems.push({ title: 'Mail', icon: 'mdi-email-outline', to: '/mail' })
			this.menuItems.push({ title: 'Rules', icon: 'mdi-shield-lock', to: '/rules' })
			this.menuItems.push({ title: 'Appliances / Requests', icon: 'mdi-router-network', to: '/orders' })
			const paginatedUsers = await findAllUsers({}, 1, 1000)
			const grouped = paginatedUsers.rows.reduce((prev: any, current: any) => {
				prev[current.customer_name] = prev[current.customer_name] || []
				prev[current.customer_name].push({ text: current.contact_person, value: current.id })
				return prev
			}, {})
			Object.keys(grouped)
				.sort()
				.forEach(company => {
					this.usersToImpersonate.push({ header: company })
					for (const user of grouped[company]) {
						this.usersToImpersonate.push(user)
					}
					this.usersToImpersonate.push({ divider: true })
				})
		}
	}

	private impersonate() {
		console.log('impersonate', this.userToImpersonate)
		impersonateUser(this.userToImpersonate).then(json => {
			console.log('imp token', json.token)
			this.$store.dispatch('app/setToken', json.token)
			this.$store.dispatch('app/setUser', json.user)
			this.$router.replace('/dashboard')
			this.loadData()
		})
	}
}
</script>
<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
	/* border: 1px solid green; */
	-webkit-text-fill-color: #aaa;
	-webkit-box-shadow: 0 0 0px 1000px #1e1e1e inset;
	transition: background-color 5000s ease-in-out 0s;
}
body .v-btn.primary {
	background: none !important;
	border-width: 1px !important;
	border-style: solid !important;
	color: #2196f3 !important;
}
body .v-btn.primary[disabled] {
	border-color: rgba(255, 255, 255, 0.3) !important;
}
body aside.v-navigation-drawer {
	background: #181818 !important;
}
</style>
<style scoped>
.v-main {
	overflow: auto;
	display: flex;
}
.sheet {
	align-items: center;
}
.avatar {
	margin-left: -4px;
}
.logo {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
	margin-bottom: 30px;
}
.logo img {
	width: 200px;
	height: 48px;
}
.small-logo {
	width: 75px;
	height: 18px;
}
.login {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.box {
	width: 100%;
	max-width: 400px;
}
.v-navigation-drawer a {
	text-decoration: none;
}
.row {
	margin: 0 -12px;
}
.profile {
	width: 300px;
	background: #111;
}
.profile .v-sheet {
	color: #ccc !important;
	opacity: 0.9;
	background: #272727;
}
.profile a {
	text-decoration: none;
}
</style>
