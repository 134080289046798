import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.engine)?_c(VCard,{attrs:{"elevation":"4"}},[_c(VForm,{staticStyle:{"padding":"30px"},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c(VContainer,[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":"img/logo-medium.png"}})]),_c('div',{staticClass:"text-center mb-8"},[_c('h4',[_vm._v("Choose a new password")])]),_c(VRow,[_c(VTextField,{staticClass:"mb-6",attrs:{"append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"rules":[_vm.rules.required, _vm.rules.min, _vm.rules.strength],"validate-on-blur":"","type":_vm.showPassword ? 'text' : 'password',"label":"Password"},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c(VProgressLinear,{staticClass:"mb-4",attrs:{"color":_vm.score.color,"value":_vm.score.value}})],1),_c('div',{staticClass:"text-center"},[_c(VBtn,{staticClass:"primary",attrs:{"type":"submit","elevation":"2"}},[_vm._v(" Set password ")])],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }