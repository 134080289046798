import { get } from './api'

export function getCustomerDevices() {
	return get(`/api/me/customer-devices`)
}
export function getManager() {
	return get(`/api/me/manager`)
}
export function getRecipient() {
	return get(`/api/me/recipient`)
}
