import Vue from 'vue'
// import '@mdi/font/css/materialdesignicons.css'
import Vuetify, {
	VApp,
	VAvatar,
	VBtn,
	VCard,
	VContainer,
	VDataTable,
	VForm,
	VIcon,
	VList,
	VListItem,
	VMain,
	VNavigationDrawer,
	VRadio,
	VRadioGroup,
	VRow,
	VSheet,
	VSpacer,
	VSystemBar,
	VTextField,
} from 'vuetify/lib'

Vue.use(Vuetify, {
	components: {
		VApp,
		VAvatar,
		VBtn,
		VCard,
		VContainer,
		VDataTable,
		VForm,
		VIcon,
		VList,
		VListItem,
		VMain,
		VNavigationDrawer,
		VRadio,
		VRadioGroup,
		VRow,
		VSheet,
		VSpacer,
		VSystemBar,
		VTextField,
	},
})

export default new Vuetify({
	theme: {
		dark: true,
		themes: {
			light: {
				primary: '#ee44aa',
				secondary: '#424242',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FFC107',
			},
		},
	},
})
