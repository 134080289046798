interface DialogState {
	deleteTrustedRule: boolean
	duplicateTrustedRule: boolean
	deleteEmailAlert: boolean
	resetRules: boolean
	updateRules: boolean
}

const Dialogs: DialogState = {
	deleteTrustedRule: false,
	duplicateTrustedRule: false,
	deleteEmailAlert: false,
	resetRules: false,
	updateRules: false,
}

type validNames = 'deleteTrustedRule' | 'duplicateTrustedRule' | 'deleteEmailAlert' | 'resetRules' | 'updateRules'

export const dialogs = {
	namespaced: true,
	state: Dialogs,
	mutations: {
		showDialog(state: DialogState, name: validNames) {
			state[name] = true
		},
		hideDialog(state: DialogState, name: validNames) {
			state[name] = false
		},
	},
	actions: {
		showDialog({ commit }: any, name: validNames) {
			commit('showDialog', name)
		},
		hideDialog({ commit }: any, name: validNames) {
			commit('hideDialog', name)
		},
	},
	getters: {},
}
