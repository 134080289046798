import { CustomerDevicesMap, DevicesByIdMap } from '../types'

interface DevicesState {
	customerDevices: CustomerDevicesMap
	visibleDeviceIds: string[]
	devicesById: DevicesByIdMap
}

const devicesState: DevicesState = {
	customerDevices: {},
	visibleDeviceIds: [],
	devicesById: {},
}

export const devices = {
	namespaced: true,
	state: devicesState,
	mutations: {
		setCustomerDevices(state: DevicesState, devices: CustomerDevicesMap) {
			state.customerDevices = devices
		},
		setVisibleDeviceIds(state: DevicesState, ids: string[]) {
			state.visibleDeviceIds = ids
		},
		setDevicedsById(state: DevicesState, map: DevicesByIdMap) {
			state.devicesById = map
		},
	},
	actions: {
		setCustomerDevices({ commit }: any, devices: CustomerDevicesMap) {
			commit('setCustomerDevices', devices)
			const allDeviceIds: string[] = Object.keys(devices)
				.map((customerId: any) => {
					return devices[customerId].devices.map(d => d.id)
				})
				.flat()
			commit('setVisibleDeviceIds', allDeviceIds)
			const devicesById: DevicesByIdMap = Object.keys(devices).reduce((prev: any, current: any) => {
				const customer = devices[current]
				customer.devices.forEach(device => {
					prev[device.id] = device
				})
				return prev
			}, {})
			commit('setDevicedsById', devicesById)
		},
	},
	getters: {},
}
