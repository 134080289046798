import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import '../public/css/main.css'
import router from './router'
import store from './store'
import './plugins/vcalendar'
import VueSpinners from 'vue-spinners'
import VueNumber from 'vue-number-animation'
import vuetify from './plugins/vuetify'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
// import VueDatamaps from 'vue-datamaps'

// Vue.use(VueDatamaps)

Vue.use(VueNumber)
Vue.use(VueSpinners)
Vue.config.productionTip = false

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App),
}).$mount('#app')
