import store from '../store'

class ApiError extends Error {
	path = ''
	constructor(...params: any) {
		// Passer les arguments restants (incluant ceux spécifiques au vendeur) au constructeur parent
		super(params[0].name)

		// Maintenir dans la pile une trace adéquate de l'endroit où l'erreur a été déclenchée (disponible seulement en V8)

		this.name = params[0].name
		// Informations de déboguage personnalisées
		this.message = params[0].message
		this.path = params[0].path
		// this.date = new Date()
	}
}

export function get(url: string) {
	const state: any = store.state
	const token = state.app.token
	return fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then(async resp => {
			if (resp.status === 401) {
				const msg = await resp.text()
				throw new ApiError({ name: msg })
			}
			return resp
		})
		.then(resp => resp.json())
		.catch(e => {
			console.error(e)
			if (e.name === 'TokenExpiredError') {
				store.dispatch('app/logout')
				return
			}
			if (e.name === 'JsonWebTokenError') {
				store.dispatch('app/logout')
				return
			}
			throw e
		})
}
export function getText(url: string) {
	const state: any = store.state
	const token = state.app.token
	return fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	})
		.then(async resp => {
			if (resp.status === 401) {
				const msg = await resp.json()
				throw new ApiError(msg)
			}
			return resp
		})
		.then(resp => resp.text())
		.catch(e => {
			console.error(e)
			if (e.name === 'TokenExpiredError') {
				store.dispatch('app/logout')
				return
			}
			if (e.name === 'JsonWebTokenError') {
				store.dispatch('app/logout')
				return
			}
			throw e
		})
}

export function post(url: string, data: any, method = 'POST') {
	const state: any = store.state
	const token = state.app.token
	return fetch(url, {
		method,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	})
		.then(async resp => {
			if (resp.status === 401) {
				const msg = await resp.json()
				throw new ApiError(msg)
			} else if (resp.status > 210) {
				const err = await resp.json()
				if (Array.isArray(err)) {
					const type = err[0].type || 'unknown'
					throw new ApiError({ name: type, message: err })
				} else {
					throw new ApiError(err)
				}
			}
			return resp
		})
		.then(resp => resp.json())
		.catch(e => {
			console.error(e)
			if (e.name === 'TokenExpiredError') {
				store.dispatch('app/logout')
				return
			}
			if (e.name === 'JsonWebTokenError') {
				store.dispatch('app/logout')
				return
			}
			throw e
		})
}
export function put(url: string, data: any) {
	return post(url, data, 'PUT')
}
export function patch(url: string, data: any) {
	return post(url, data, 'PATCH')
}
export function del(url: string, data: any = {}) {
	return post(url, data, 'DELETE')
}

// export function del(url: string): Promise<any> {
// 	const state: any = store.state
// 	const token = state.app.token
// 	return fetch(url, {
// 		method: 'DELETE',
// 		headers: {
// 			Authorization: `Bearer ${token}`,
// 		},
// 	}).catch(e => {
// 		console.error(e)
// 		if (e.name === 'TokenExpiredError') {
// 			store.dispatch('app/logout')
// 		}
// 		if (e.name === 'JsonWebTokenError') {
// 			store.dispatch('app/logout')
// 		}
// 	})
// }
