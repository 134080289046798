<template>
	<v-card elevation="4" v-if="engine">
		<v-form style="padding: 30px" @submit.prevent="resetPassword">
			<v-container>
				<div class="logo">
					<img src="img/logo-medium.png" />
				</div>
				<div class="text-center mb-8"><h4>Choose a new password</h4></div>
				<v-row>
					<v-text-field
						v-model="password"
						:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
						:rules="[rules.required, rules.min, rules.strength]"
						validate-on-blur
						:type="showPassword ? 'text' : 'password'"
						label="Password"
						class="mb-6"
						@click:append="showPassword = !showPassword"
					></v-text-field>
					<v-progress-linear :color="score.color" :value="score.value" class="mb-4"></v-progress-linear>
				</v-row>

				<div class=" text-center">
					<v-btn class="primary" type="submit" elevation="2">
						Set password
					</v-btn>
				</div>
			</v-container>
		</v-form>
	</v-card>
</template>

<script lang="ts">
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { setPassword } from '@/api/users'

const appModule = namespace('app')

@Component({
	components: {},
})
export default class ResetPasswordForm extends Vue {
	@appModule.Action('showSnack') private showSnack(snack: any) {}
	private showPassword = false
	private passError = false
	private password = ''
	private engine: any = null
	private passwordErrors: any[] = []
	private rules = {
		required: (value: string) => !!value || 'Enter a password',
		min: (v: string) => v.length >= 8 || 'Use 8 characters or more for your password',
		strength: (v: string) => {
			if (!this.engine) return ''
			this.engine(v).score >= 3 ||
				'Please choose a stronger password. Try a mix of letters, numbers, and symbols.'
		},
	}
	private get score() {
		if (!this.engine) return 0
		const result = this.engine(this.password)

		switch (result.score) {
			case 4:
				return { color: 'light-blue', value: 100 }
			case 3:
				return { color: 'light-green', value: 75 }
			case 2:
				return { color: 'yellow', value: 50 }
			case 1:
				return { color: 'orange', value: 25 }
			default:
				return { color: 'red', value: 0 }
		}
	}

	public async resetPassword() {
		this.passwordErrors = []
		if (this.password.length < 4) {
			this.passwordErrors.push('The password is too short')
		}
		try {
			const token = this.$route.query.token as string
			const resp = await setPassword(this.password, token.trim())
			if (resp.token) {
				// this.$store.dispatch('app/setToken', resp.token)
				// this.$store.dispatch('app/setUser', resp.user)
				this.$store.dispatch('app/logout')
				window.location.href = '/'
				this.showSnack({ message: 'Password reset successful', color: 'green' })
			} else {
				this.passError = true
			}
		} catch (e) {
			this.passError = true
		}
	}

	protected async loadData() {
		const zxcvbn = await import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn')
		this.engine = zxcvbn.default
	}

	protected async mounted() {
		this.loadData()
	}
}
</script>
