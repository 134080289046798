import { ActionContext, ActionTree } from 'vuex'
interface ButtonState {
	deleteFlow: {
		disabled: boolean
	}
	duplicateFlow: {
		disabled: boolean
	}
	deleteEmailAlert: {
		disabled: boolean
	}
	resetRules: {
		disabled: boolean
	}
	updateRules: {
		disabled: boolean
	}
}

const Buttons: ButtonState = {
	deleteFlow: {
		disabled: true,
	},
	duplicateFlow: {
		disabled: true,
	},
	deleteEmailAlert: {
		disabled: true,
	},
	resetRules: {
		disabled: true,
	},
	updateRules: {
		disabled: true,
	},
}

type validNames = 'deleteFlow' | 'duplicateFlow' | 'resetRules'

type Mutations = {
	[key: string]: any
}

type AugmentedActionContext = {
	commit<K extends keyof Mutations>(key: K, payload?: Parameters<Mutations[K]>[1]): ReturnType<Mutations[K]>
} & Omit<ActionContext<ButtonState, ButtonState>, 'commit'>

interface Actions {
	enableButton({ commit }: AugmentedActionContext, name: validNames): void
	disableButton({ commit }: AugmentedActionContext, name: validNames): void
}

const actions: ActionTree<ButtonState, ButtonState> & Actions = {
	enableButton({ commit }, name: validNames) {
		commit('enableButton', name)
	},
	disableButton({ commit }, name: validNames) {
		commit('disableButton', name)
	},
}

export const buttons = {
	namespaced: true,
	state: Buttons,
	mutations: {
		enableButton(state: ButtonState, name: validNames) {
			state[name].disabled = false
		},
		disableButton(state: ButtonState, name: validNames) {
			state[name].disabled = true
		},
	},
	actions,
	getters: {},
}
